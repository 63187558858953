import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  useTheme,
  useMediaQuery,
  Link,
  Grid,
} from "@mui/material";
import ProfilePic from "../assets/profile.webp";

const BioPage: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 64px)", // Adjust based on your AppBar height
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Card sx={{ maxWidth: 800, width: "100%", boxShadow: 3 }}>
        <CardContent sx={{ p: 4 }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Avatar 
                alt="Brynn Crowley" 
                src={ProfilePic} 
                sx={{ 
                  width: 200, 
                  height: 200, 
                  mb: 2,
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
              />
              <Typography variant="h4" component="h1" gutterBottom align="center">
                Brynn Crowley
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" align="center">
                He/Him
                <Link href="https://github.com/Crowley723" sx={{ ml: 1 }}>
                  GitHub
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box>
                <Typography variant="body1" paragraph>
                  Brynn Crowley is a dedicated computer science student
                  pursuing a Bachelor of Science, with an expected graduation
                  in December 2025. Passionate about technology and its
                  practical applications, Brynn combines academic learning
                  with hands-on experience to develop a well-rounded skill set
                  in the field of computer science.
                </Typography>
                <Typography variant="body1">
                  Recently, Brynn has been focusing on building and
                  maintaining a comprehensive home lab environment. This
                  project encompasses various technologies including Docker
                  containerization, Kubernetes orchestration with K3s, and
                  network management using OPNsense. Additionally, Brynn has
                  contributed to the open-source authentication project
                  Authelia and other minor projects.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BioPage;