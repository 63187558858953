import React from "react";
import { Box, Typography, Link, useTheme, Divider } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import { LinkedIn } from "@mui/icons-material";

const Footer: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: "auto",
                backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800],
            }}
        >
            <Typography variant="body2" color="text.secondary" align="center">
                © {new Date().getFullYear()} Brynn Crowley
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                }}
            >
                <Link
                    href="https://github.com/Crowley723"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "&:hover": {
                            color: theme.palette.primary.main,
                        },
                        mr: 2,
                    }}
                >
                    <GitHubIcon sx={{ mr: 0.5 }} fontSize="small" />
                    GitHub
                </Link>
                <Divider orientation="vertical" flexItem sx={{ mx: 2, height: 20 }} />
                <Link
                    href="https://www.linkedin.com/in/brynn-crowley/"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="inherit"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        "&:hover": {
                            color: theme.palette.primary.main,
                        },
                        ml: 2,
                    }}
                >
                    <LinkedIn sx={{ mr: 0.5 }} fontSize="small" />
                    LinkedIn
                </Link>
            </Box>
            <Typography variant="caption" color="text.secondary" align="center" sx={{ display: "block", mt: 1 }}>
                Attributions: Authelia logo © Authelia project. Licensed under Apache License 2.0.
                <Link href="https://www.authelia.com" target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}>
                    Learn more
                </Link>
            </Typography>
        </Box>
    );
};

export default Footer;
