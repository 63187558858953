import React from 'react';
import { Box, Typography, Button, Paper, useTheme, Grid2 } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const IndexView: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: 'calc(100vh - 64px)', // Adjust based on your AppBar height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(3),
      }}
    >
      <Paper elevation={3} sx={{ padding: theme.spacing(4), maxWidth: 800, width: '100%' }}>
        <Typography variant="h2" component="h1" gutterBottom align="center">
          Welcome
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          I'm Brynn Crowley, a Computer Science Student
        </Typography>
        <Typography variant="body1" paragraph align="center">
          Always interested in learning new things, recently I have been interested in application security.
          Explore my projects and learn more about my journey in computer science.
        </Typography>
        <Grid2 container spacing={2} justifyContent="center">
          <Grid2>
            <Button component={RouterLink} to="/about" variant="contained" color="primary">
              About Me
            </Button>
          </Grid2>
          <Grid2>
            <Button component={RouterLink} to="/projects" variant="outlined" color="primary">
              My Projects
            </Button>
          </Grid2>
        </Grid2>
      </Paper>
    </Box>
  );
};

export default IndexView;