import React from "react";
import { Box, Paper, Stack, Typography, Divider, List, ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
        backgroundColor: "#1A2027",
    }),
}));

const HomelabView = () => {
    const dockerContainers = [
        "Traefik (Reverse Proxy)",
        "Authelia (Authentication)",
        "Jellyfin (Media Server)",
        "NextCloud (File Hosting)",
        "Vaultwarden (Password Manager)",
        "Gitea (Self-hosted Git service)",
        "Grafana & Prometheus (Monitoring)",
        "Home Assistant (Home Automation)",
        "Uptime Kuma (Uptime Monitoring)",
        "PhotoPrism (Photo Management)",
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: "60vh",
                width: "100%",
                py: 4,
            }}
        >
            <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 3, textAlign: "center" }}>
                Home Lab Experience
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 800 }}>
                <Stack spacing={4} sx={{ px: 2, py: 4 }}>
                    <Item>
                        <Typography variant="h5" gutterBottom>
                            Deployed Docker Containers
                        </Typography>
                        <Typography variant="body1" component={'p'}>
                            My home lab includes a diverse set of Docker containers, providing various services and
                            learning opportunities:
                        </Typography>
                        <List>
                            {dockerContainers.map((container, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={container} />
                                </ListItem>
                            ))}
                        </List>
                        <Typography variant="body1">
                            These containers have allowed me to explore different technologies and services while
                            maintaining a flexible and scalable infrastructure.
                        </Typography>
                    </Item>

                    <Item>
                        <Typography variant="h5" gutterBottom>
                            Proxmox Server
                        </Typography>
                        <Typography variant="body1" component="p">
                            In addition to Docker containers, I manage a Proxmox server that hosts:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="K3s Kubernetes Cluster"
                                    secondary="Exploring container orchestration and microservices architecture"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="OPNsense"
                                    secondary="Network security and advanced routing capabilities"
                                />
                            </ListItem>
                        </List>
                        <Typography variant="body1">
                            This setup has provided hands-on experience with virtualization, container orchestration,
                            and network management.
                        </Typography>
                    </Item>
                    <Item>
                        <Typography variant="h5" gutterBottom >
                            Automated Backup System
                        </Typography>
                        <Typography variant="body1" gutterBottom component="p">
                            Developed a comprehensive backup solution using shell scripting to automate the process of
                            creating, packaging, encrypting, and uploading backups to cloud storage.
                        </Typography>
                        <Typography variant="h6">
                            Key features and skills:
                            <List>
                                <ListItem>
                                    <ListItemText>Bash scripting for automation</ListItemText>
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Data compression and archiving" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Encryption using GPG for data security" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Integration with cloud storage API (Backblaze B2)" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Scheduling with cron for regular
                                    backups"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Error handling and logging for reliable operations" />
                                </ListItem>
                            </List>
                        </Typography>
                    </Item>

                    <Item>
                        <Typography variant="h5" gutterBottom>
                            Key Learnings and Skills Developed
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="Container Orchestration"
                                    secondary="Gained practical experience with Docker and Kubernetes"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Network Management"
                                    secondary="Implemented reverse proxy, VPN, and security measures"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="High Availability"
                                    secondary="Designed systems for reliability and fault tolerance"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Infrastructure as Code"
                                    secondary="Utilized Docker Compose and Kubernetes manifests for deployment"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Monitoring and Logging"
                                    secondary="Implemented comprehensive monitoring solutions"
                                />
                            </ListItem>
                        </List>
                    </Item>
                </Stack>
            </Box>
        </Box>
    );
};

export default HomelabView;
