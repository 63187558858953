import React from "react";
import {
    Box,
    Grid2,
    Link,
    Paper,
    Stack,
    SvgIcon,
    Typography,
    Divider,
    Button,
    useTheme,
    ListItem,
    ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as AutheliaLogo } from "../assets/authelia-icon.svg";
import StorageIcon from "@mui/icons-material/Storage";

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[8],
    "&:hover": {
        boxShadow: theme.shadows[12],
    },
    transition: theme.transitions.create(["box-shadow"], {
        duration: theme.transitions.duration.short,
    }),
}));

const ProjectsView = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                minHeight: "60vh",
                width: "100%",
                py: 4,
                backgroundColor: theme.palette.background.default,
            }}
        >
            <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 3, textAlign: "center" }}>
                Projects
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 800 }}>
                <Stack spacing={4} sx={{ px: 2, py: 4 }}>
                    <Item>
                        <Grid2 container spacing={2}>
                            <Grid2 container spacing={2} alignItems="center">
                                <Grid2 size={{ xs: 12, sm: 4, md: 3 }} sx={{ textAlign: "center" }}>
                                    <Link href="https://www.authelia.com" target="_blank" rel="noopener noreferrer">
                                        <SvgIcon sx={{ fontSize: 100 }}>
                                            <AutheliaLogo />
                                        </SvgIcon>
                                    </Link>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 8, md: 9 }}>
                                    <Typography>
                                        <strong>Authelia</strong> is an open source authentication and authorization
                                        provider that allows users to implement secure single sign-on for their self
                                        hosted applications.
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="h6" gutterBottom>
                                    My Contributions
                                </Typography>
                                <Typography variant="body2">
                                    Working on Authelia has been a really enriching experience. I have learned the value
                                    of teamwork on a project as large as Authelia. Working on an large, established code
                                    base has made me a better developer. Learning to figure out how an established code
                                    base works and how to implement new features was a challenge. Once I understood how
                                    the project is organized it became much easier to follow what the code was doing
                                    <ListItemText
                                        primary="Password Change Feature"
                                        secondary="Allows users to change their password without using the reset password flow."
                                    />
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        href="https://www.authelia.com"
                                        variant="contained"
                                        color="primary"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Learn More About Authelia
                                    </Button>
                                    <Typography variant="caption" sx={{ display: "block", mt: 2, fontStyle: "italic" }}>
                                        Authelia logo © Authelia project. Licensed under Apache License 2.0. 
                                    </Typography>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Item>
                    <Item>
                        <Grid2 container spacing={2}>
                            <Grid2 container spacing={2} alignItems="center">
                                <Grid2 size={{ xs: 12, sm: 4, md: 3 }} sx={{ textAlign: "center" }}>
                                    <SvgIcon sx={{ fontSize: 100 }}>
                                        <StorageIcon />
                                    </SvgIcon>
                                </Grid2>
                                <Grid2 size={{ xs: 12, sm: 8, md: 9 }}>
                                    <Typography variant="h6" gutterBottom>
                                        Home Lab
                                    </Typography>
                                    <Typography>
                                        My home lab is a comprehensive environment featuring Docker containers, a
                                        Proxmox server running K3s and OPNsense, and various self-hosted services. This
                                        setup has allowed me to gain hands-on experience with container orchestration,
                                        virtualization, networking, and infrastructure management.
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={{ xs: 12 }}>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="h6" gutterBottom>
                                    Key Learnings
                                </Typography>
                                <Typography variant="body2">
                                    Through this project, I've developed skills in Docker, Kubernetes, network security,
                                    high availability design, and infrastructure as code. It's been an invaluable
                                    platform for practical application of DevOps principles and technologies.
                                </Typography>
                                <Box sx={{ mt: 2 }}>
                                    <Button href="/projects/homelab" variant="contained" color="primary">
                                        Learn More About My Home Lab
                                    </Button>
                                </Box>
                            </Grid2>
                        </Grid2>
                    </Item>
                </Stack>
            </Box>
        </Box>
    );
};

export default ProjectsView;
