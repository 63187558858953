import React, { useState, useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode } from '@mui/material';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import IndexView from "./views/IndexView";
import ProjectsView from "./views/ProjectsView";
import AboutView from "./views/AboutView";
import HomelabView from './views/HomelabView';
import Footer from './components/Footer';

function App() {
    const [mode, setMode] = useState<PaletteMode>(() => {
        const savedMode = localStorage.getItem('theme') as PaletteMode;
        return savedMode || 'light';
      });
    
      const colorMode = useMemo(
        () => ({
          toggleColorMode: () => {
            setMode((prevMode) => {
              const newMode = prevMode === 'light' ? 'dark' : 'light';
              localStorage.setItem('theme', newMode);
              return newMode;
            });
          },
        }),
        [],
      );
    
      const theme = useMemo(
        () =>
          createTheme({
            palette: {
              mode,
            },
          }),
        [mode],
      );
    
      useEffect(() => {
        const handleStorageChange = (e: StorageEvent) => {
          if (e.key === 'theme' && e.newValue) {
            setMode(e.newValue as PaletteMode);
          }
        };
    
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
      }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <ResponsiveAppBar colorMode={colorMode} />
        <Routes>
          <Route path="/projects" element={<ProjectsView />} />
          <Route path="/projects/homelab" element={<HomelabView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/*" element={<IndexView />} />
        </Routes>
        <Footer/>
      </Router>
    </ThemeProvider>
  );
}

export default App;